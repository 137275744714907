<template>
  <div>
    <el-affix>
      <nav-bar></nav-bar>
    </el-affix>
    <tool-box></tool-box>

    <!-- 顶部标题 -->
    <div class="top" @click="showSpecialList = true">
      <img :src="currentSpecialChild.spec_banner" alt="">
      <div class="container">
        <i class="el-icon-s-unfold"></i>
        <span>{{ currentSpecialChild.title }}</span>
      </div>
    </div>
    <!-- 专题列表 -->
    <el-dialog title="更换专题" v-model="showSpecialList" width="900px">
      <div class="speicla-list">
        <div class="special-list-item" v-for="item in specialList" :key="item.id">
          <div class="item-title">{{ item.title }}</div>
          <div class="item-child" @click="doChangeSpecialChild(child)" v-for="child in item.children" :key="child.id">{{ child.title }}</div>
        </div>
      </div>
    </el-dialog>

    <div class="container mt-2 mb-2">
      <!-- 课程列表 -->
      <el-row :gutter="20">
        <!-- 专题banner图 -->
        <el-col :span="12">
          <img class="special-thumb" :src="currentSpecialChild.spec_thumb" alt="">
        </el-col>
        <!-- 列表 -->
        <el-col :span="6" v-for="(item, index) in courseList" :key="item.id">
          <el-card v-show="index < showCourseNumber" class="course" @click="goCourseDetail(item)">
            <div style="padding: 20px;">
              <div class="course-title">{{ item.title }}</div>
              <div class="course-spec">
                <span>{{ currentSpecialChild.title }}</span>
              </div>
              <div class="course-desc">
                <span v-for="(desc, index) in item.service" :key="index">{{ desc }}</span>
              </div>
              <div class="course-teacher">
                <div class="course-teacher-item" v-for="teacher in item.teacher" :key="teacher.name">
                  <div class="course-teacher-item-img"><img :src="teacher.thumb" alt=""></div>
                  <div>{{ teacher.name }}</div>
                </div>
              </div>
              <el-divider></el-divider>
              <div class="course-footer">
                <div>{{ item.combo ? item.combo[0].buy_count : Math.ceil(Math.random() * 10000) }}人已购买</div>
                <div class="course-price">￥{{ item.sale_price }}<span>起</span></div>
              </div>
            </div>
          </el-card>
        </el-col>
        <el-col :span="24" v-show="showCourseNumber < courseList.length">
          <div class="see-more" @click="doShowMoreCourseList">
            <el-tooltip class="box-item" effect="dark" content="查看更多" placement="top-start">
              <svg-icon iconClass="jiantouarrow486"></svg-icon>
            </el-tooltip>
          </div>
        </el-col>
      </el-row>

      <!-- 考试日期 -->
      <div class="exam-time">
        <el-steps align-center>
          <el-step :title="item.title" v-for="item in timeLineList" :key="item.id">
            <template #description>
              <div class="exam-content">
                <div class="exam-content-desc">{{ item.time_desc }}</div>
                <!-- <div class="exam-content-sub">
                  <div @click="goNewsDetail(item.sub_title_url1)">{{ item.sub_title1 }}</div>
                  <div @click="goNewsDetail(item.sub_title_url2)">{{ item.sub_title2 }}</div>
                  <div @click="goNewsDetail(item.sub_title_url3)">{{ item.sub_title3 }}</div>
                </div> -->
              </div>
            </template>
          </el-step>
        </el-steps>
      </div>


      <el-row :gutter="20">
        <!-- 热点资讯 -->
        <el-col :span="18">
          <div class="module">
            <div class="module-header">
              <div class="header-title">热点资讯</div>
              <div class="header-more" @click="goNews">更多>></div>
            </div>
            <div class="news-hot-list">
              <div class="list-item" @click="goNewsDetail(item.article_id)" :title="item.article_title" v-for="(item,index) in hotNewsList" :key="index">{{ item.article_title }}</div>
            </div>
          </div>
        </el-col>
        <!-- 免费题库 -->
        <el-col :span="6">
          <div class="module">
            <div class="module-header">
              <div class="header-title">免费题库</div>
              <div class="header-more" @click="goExamList(null)">更多>></div>
            </div>
            <div class="exam-list">
              <div class="exam-list-item" @click="goExamList(7)">
                <div class="item-img"><svg-icon iconClass="paper-moni" style="color: #F56C6C;"></svg-icon></div>
                <div>模拟试卷</div>
              </div>
              <div class="exam-list-item" @click="goExamList(3)">
                <div class="item-img"><svg-icon iconClass="paper-lianxi" style="color: #E6A23C;"></svg-icon></div>
                <div>每日一练</div>
              </div>
              <div class="exam-list-item" @click="goExamList(4)">
                <div class="item-img"><svg-icon iconClass="paper-chapter" style="color: #409EFF;"></svg-icon></div>
                <div>章节练习</div>
              </div>
              <div class="exam-list-item" @click="goExamList(5)">
                <div class="item-img"><svg-icon iconClass="paper-true" style="color: #F56C6C;"></svg-icon></div>
                <div>往年真题</div>
              </div>
            </div>
          </div>
        </el-col>
      </el-row>

      <!-- 各科套餐对比 -->
      <div class="module combo-table">
        <div class="module-header">
          <div class="header-title">{{ currentSpecialChild.title }}套餐辅导课程</div>
        </div>
        <div class="combo-table-content">
          <!-- 标头 -->
          <el-affix :offset="140" target=".combo-table-content" z-index="99">
            <div class="content-row header">
              <div class="content-row-col">&nbsp;&nbsp;&nbsp;</div>
              <div class="content-row-col horizontal-center vertical-center" v-for="item in showCourseList" :key="item.id" v-html="item.sub_title"></div>
            </div>
          </el-affix>
          <!-- 包含班级 -->
          <div class="content-row">
            <div class="content-row-col">包含班级</div>
            <div class="content-row-col" v-for="item in showCourseList" :key="item.id" v-html="item.include_class"></div>
          </div>
          <!-- 课程保障 -->
          <div class="content-row">
            <div class="content-row-col">课程保障</div>
            <div class="content-row-col" v-for="item in showCourseList" :key="item.id" v-html="item.course_safeguard"></div>
          </div>
          <!-- 独享资料 -->
          <div class="content-row">
            <div class="content-row-col">独享资料</div>
            <div class="content-row-col" v-for="item in showCourseList" :key="item.id" v-html="item.give_info"></div>
          </div>
          <!-- 服务保障 -->
          <div class="content-row">
            <div class="content-row-col">服务保障</div>
            <div class="content-row-col" v-for="item in showCourseList" :key="item.id" v-html="item.service_safeguard"></div>
          </div>
          <!-- 优惠价格 -->
          <div class="content-row">
            <div class="content-row-col ">优惠价格</div>
            <div class="content-row-col horizontal-center vertical-center" v-for="item in showCourseList" :key="item.id">
              <div class="price">￥{{ item.sale_price }}<span>起</span></div>
            </div>
          </div>
          <!-- 最后一行 -->
          <div class="content-row footer">
            <div class="content-row-col">&nbsp;&nbsp;&nbsp;&nbsp;</div>
            <div class="content-row-col  horizontal-center vertical-center" v-for="item in showCourseList" :key="item.id">
              <el-button type="danger" @click="goCourseDetail(item)">立即购买</el-button>
            </div>
          </div>
        </div>
      </div>

      <!-- 考试资讯 -->
      <div class="module news">
        <div class="module-header">
          <div class="header-title">考试资讯</div>
          <div class="news-type">
            <div :class="currentNewsType.id == item.id ? 'active' : ''" @mouseover="doChangeNewsType(item)" v-for="item in newsList" :key="item.id">{{ item.title }}</div>
          </div>
        </div>
        <div class="news-content">
          <div class="news-content-item" @click="goNewsDetail(item.article_id)" v-for="item in currentNewsType.article" :key="item.article_id">
            <div class="item-title" :title="item.article_title"><span>{{ item.article_title }}</span></div>
            <div class="item-time">{{ item.create_time.substring(0, 10) }}</div>
          </div>
        </div>
      </div>

      <!-- 教师团队 -->
      <div class="module teacher-team">
        <div class="module-header">
          <div class="header-title">教师团队</div>
          <div class="header-more" @click="goTeacher">更多>></div>
        </div>
        <el-carousel indicator-position="none" type="card">
          <el-carousel-item v-for="item in teacherList" :key="item.id">
            <div class="teacher-team-item">
              <div class="teacher-team-item-info">
                <div class="name">{{ item.name }}</div>
                <div class="info">{{ item.content }}</div>
                <div class="teacher-home-page" @click="goTeacherDetail(item)">个人主页>></div>
                <el-button type="primary" plain round @click="goTeacherDetail(item)">试听他的课</el-button>
              </div>
                <div class="teacher-team-item-img">
                  <img :src="item.thumb" />
                </div>
            </div>
          </el-carousel-item>
        </el-carousel>
      </div>
    </div>

    <web-footer></web-footer>
  </div>
</template>

<script type="text/javascript">
import NavBar from "@/components/page/NavBar"
import ToolBox from "@/components/toolbox"
import WebFooter from "@/components/page/WebFooter"
import SvgIcon from '@/components/svgicon'
import { getSpec } from '@/api/special'
import { getComboBySpec } from '@/api/course'
import { getSpecTimeLine, getSpecArticleList } from '@/api/news'
import { setSessionStorage } from '@/utils/auth'
import { getTeacher } from '@/api/teacher'
import { stripHTML } from '@/utils'

export default {
  components: {
    NavBar,
    ToolBox,
    WebFooter,
    SvgIcon,
  },
  data() {
    return {
      // 专题列表
      specialList: [],
      // 当前二级专题
      currentSpecialChild: {},
      // 是否展示专题列表
      showSpecialList: false,
      // 套餐列表
      courseList: [],
      // 分类及分类下资讯列表
      newsList: [],
      // 当前资讯类型
      currentNewsType: {},
      // 时间线
      timeLineList: [],
      // 热点资讯列表
      hotNewsList: [],
      // 教师列表
      teacherList: [],
      // 套餐辅导课程列表，仅显示后6个
      showCourseList: [],
      // 套餐显示个数，默认显示6个
      showCourseNumber: 6,

    }
  },
  created() {
    // 获取专题
    getSpec().then(res => {
      if (res.code == 1) {
        this.specialList = res.data
        // 找到当前路由中的二级专题，并请求数据
        res.data.forEach(item => {
          item.children.forEach(child => {
            if (child.id == this.$route.params.id) {
              this.doChangeSpecialChild(child)
              return
            }
          })
        })
      }
    })
  },
  methods: {
    // 切换二级专题，并获取各项数据
    doChangeSpecialChild(child) {
      this.$router.push('/special/' + child.id)
      this.showSpecialList = false
      this.currentSpecialChild = child
      this.getData(child.id)
      this.getTimeLine(child.id)
      this.getHotNews(child.id)
      this.getTeacherList(child.id)
    },
    // 获取各项数据
    getData(specialChildId) {
      getComboBySpec({spec_id: specialChildId}).then(res => {
        if (res.code == 1) {
          // 当前专题
          this.currentSpecialChild = res.data.info
          // 当前专题下的套餐
          this.courseList = []
          for (let i = res.data.comboclass.length - 1; i >=0 ; i--) {
            let item = res.data.comboclass[i]
            if (item.status == 1) {
              item.service = item.service.split('/')
              item.sale_price = item.combo[0].sale_price
              item.combo.forEach(combo => {
                if (combo.sale_price < item.sale_price) {
                  item.sale_price = combo.sale_price
                }
              })
              this.courseList.push(item)
              // 2022-01-14 接触限值6个
              // if (this.courseList.length == 6) {
              //   return
              // }
            }
          }
          // 2022-01-14 下面套餐辅导课程限制6个
          this.showCourseList = this.courseList.slice(0, 6)
          console.log(this.showCourseList)
          // 当前专题下的各类型资讯
          this.newsList = res.data.art
          this.currentNewsType = this.newsList[0]
          this.currentNewsType.article = this.currentNewsType.article.slice(-30)
        }
      })
    },
    // 跳转到课程详情
    goCourseDetail(course) {
      this.$router.push('/coursedetail/' + course.spec_id + '/' + course.id + '/' + course.combo[0].id)
    },
    // 获取考试时间线
    getTimeLine(specId) {
      this.timeLineList = []
      const year = new Date().getFullYear()
      getSpecTimeLine({spec_id: specId, version_year: year}).then(res => {
        if (res.code == 1) {
          this.timeLineList = res.data
        }
      })
    },
    // 跳转到资讯详情
    goNewsDetail(newsUrl) {
      //this.$router.push('/newsdetail/' + newsId)
      // console.log(newsUrl)
      // this.$router.push(newsUrl)
	  this.$router.push('/newsdetail/' + newsUrl)
    },
    // 获取热点资讯
    getHotNews(specId) {
      getSpecArticleList({spec_id: specId, page: 1, limit: 25}).then(res => {
        if (res.code == 1) {
          this.hotNewsList = res.data
        }
      })
    },
    // 查看更多新闻
    goNews() {
      setSessionStorage('newsSpecialId', this.currentSpecialChild.id)
      this.$router.push('/news')
    },
    // 查看题库列表
    goExamList(type) {
      let specId = null
      this.specialList.forEach(item => {
        item.children.forEach(child => {
          if (child.id == this.currentSpecialChild.id) {
            specId = item.id
            return
          }
        })
      })
      setSessionStorage('examListParams', {
        specId: specId,
        specChildId: this.currentSpecialChild.id,
        type: type || 7,
      })
      this.$router.push('/examlist')
    },
    // 切换资讯类型
    doChangeNewsType(type) {
      this.currentNewsType = type
      this.currentNewsType.article = type.article.slice(-30)
    },
    // 获取教师列表
    getTeacherList(specId) {
      getTeacher({type: 2, spec_id: specId}).then(res => {
        if (res.code == 1) {
          res.data.forEach(item => item.content = stripHTML(item.content))
          this.teacherList = res.data
        }
      })
    },
    // 调转到教师列表
    goTeacher() {
      this.$router.push('/teacher')
    },
    // 查看教师详情
    goTeacherDetail(teacher) {
      this.$router.push('/teacherdetail/' + teacher.id)
    },
    // 查看更多课程
    doShowMoreCourseList() {
      // 每次点击添加两行
      this.showCourseNumber += 8
    },

  },
}
</script>

<style scoped lang="scss">
/* 头部 begin */
.top {
  height: 88px;
  line-height: 88px;
  position: relative;
  display: flex;
  justify-content: center;
  & > img {
    object-fit: cover;
  }
  & > .container {
    color: #fff;
    font-size: 20px;
    position: absolute;
    cursor: pointer;
  }
}
/* 头部 end */

// 专题列表 begin
.speicla-list {
  padding: 20px 0;
  display: flex;
  .special-list-item {
    width: 150px;
    text-align: center;
    border-right: 1px solid #dad9d9;
    .item-title {
      height: 36px;
      font-size: 16px;
      font-weight: bold;
    }
    .item-child {
      margin-bottom: 12px;
      cursor: pointer;
    }
    .item-child:hover {
      color: $theme-color;
    }
  }
  .special-list-item:last-child {
    border: none;
  }
}
// 专题列表 end

// 专题大图 begin
.special-thumb {
  width: 100%;
  height: 390px;
}
// 专题大图 end

// 课程列表 begin
.course {
  height: 390px;
  margin-bottom: 20px;
  .course-title {
    font-size: 18px;
    font-weight: bold;
  }
  .course-spec {
    color: #666;
    margin: 20px 0;
    span {
      margin-right: 15px;
    }
  }
  .course-desc {
    height: 48px;
    span {
      color: #666;
      font-size: 12px;
      padding: 0 5px;
      margin-top: 5px;
      margin-right: 10px;
      background-color: bisque;
    }
  }
  .course-teacher {
    margin-top: 50px;
    display: flex;
    flex-wrap: wrap;
    .course-teacher-item {
      margin-right: 10px;
      margin-bottom: 10px;
      .course-teacher-item-img {
        width: 45px;
        height: 45px;
        border-radius: 50%;
        overflow: hidden;
      }
      & > div {
        color: #666;
        font-size: 12px;
        text-align: center;
      }
    }
  }
  .course-footer {
    color: #666;
    display: flex;
    justify-content: space-between;
    align-items: baseline;
    .course-price {
      color: $price-color;
      font-size: 22px;
      font-weight: bold;
      span {
        color: #ccc;
        font-size: 12px;
      }
    }
  }
}
.course:hover {
  box-shadow: 0 0 3px 3px #ccc;
  // transform: translateY(-5px);
  // transition: all .5s;
}
// 课程展示 end

// 考试日期 begin
.exam-time {
  margin-top: 50px;
  .exam-content {
    font-size: 14px;
    .exam-content-desc {
      color: $danger-color;
      height: 40px;
      padding: 0 5px;
    }
    .exam-content-sub {
      color: $info-color;
      margin-top: 3px;
      & > div {
        margin-bottom: 5px;
        cursor: pointer;
      }
    }
  }
}
// 考试日期 end

// 公用模块 begin
.module {
  min-height: 320px;
  background-color: $background-color;
  padding: 10px 20px;
  margin-top: 50px;
  .module-header {
    height: 36px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .header-title {
      font-size: 20px;
      font-weight: bold;
      border-left: 5px solid $theme-color;
      padding-left: 5px;
    }
    .header-more {
      cursor: pointer;
    }
  }
}
// 公用模块 end

// 热点资讯 begin
.news-hot-list {
  padding: 10px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  .list-item {
    width: 260px;
    height: 26px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    cursor: pointer;
  }
  .list-item::before {
    content: "";
    width: 4px;
    height: 4px;
    border-radius: 50%;
    background-color: #333;
    margin-top: 6px;
    margin-left: -10px;
    position: absolute;
  }
  .list-item:hover {
    color: $theme-color;
  }
  .list-item:first-child {
    width: 100%;
    font-size: 16px;
    font-weight: bold;
    text-align: center;
    margin: 10px 0;
    &::before {
      width: 0;
    }
  }
}
// 热点资讯 end

// 免费题库 begin
.exam-list {
  display: flex;
  flex-wrap: wrap;
  .exam-list-item {
    width: 120px;
    text-align: center;
    margin: 20px 0;
    cursor: pointer;
    .item-img {
      width: 70px;
      height: 70px;
      background: #f5f5f5;
      border-radius: 50%;
      margin: 0 auto;
      svg {
        width: 36px;
        height: 36px;
        margin: 18px 0 0 4px;
      }
    }
  }
}
// 免费题库 end

// 套餐对比 begin
.combo-table {
  .module-header {
    .header-title {
      font-size: 24px;
    }
  }
  .combo-table-content {
    margin: 50px 0;
    .content-row {
      display: flex;
      word-wrap: break-word;
      word-break: break-all;
      .content-row-col {
        flex: 1;
        // width: 185px;
        padding: 10px;
        background: linear-gradient(90deg, #fff 95%, #f9f9f9);
        border-bottom: 1px solid #e8e8e8;
        box-sizing: border-box;
        display: flex;
        //justify-content: center;
        //align-items: center;
      }
      .vertical-center{
        align-items: center;
      }
      .horizontal-center{
        justify-content: center;
      }
      .content-row-col:first-child {
        flex: 0;
        color: #fff;
        width: 38px;
        font-size: 18px;
        background: $primary-color;
        border: none;
      }
    }
    .content-row.header {
      height: 69px;
      .content-row-col {
        color: #fff;
        font-size: 22px;
        font-weight: 700;
        border-radius: 3px 3px 0 0;
      }
      .content-row-col:first-child {
        background: $background-color;
      }
      .content-row-col:nth-child(2n) {
        background: $success-color;
      }
      .content-row-col:nth-child(3n) {
        background: $danger-color;
      }
      .content-row-col:nth-child(4n) {
        background: $primary-color;
      }
      .content-row-col:nth-child(5n) {
        background: $warning-color;
      }
      .content-row-col:nth-child(7n) {
        background: $primary-color;
      }
    }
    .content-row:nth-child(2) {
      .content-row-col:first-child {
        border-radius: 3px 0 0 0;
      }
    }
    .content-row.footer {
      height: 100px;
      .content-row-col:first-child {
        border-radius: 0 0 0 3px;
      }
    }
    .price {
      color: $price-color;
      font-size: 18px;
      span {
        color: #ccc;
        font-size: 12px;
      }
    }
  }
}
// 套餐对比 end

// 资讯 begin
.news {
  .module-header {
    .header-title {
      font-size: 24px;
    }
  }
  .news-type {
    display: flex;
    & > div {
      width: 100px;
      height: 28px;
      line-height: 28px;
      font-size: 16px;
      text-align: center;
      cursor: pointer;
    }
    & > div.active {
      color: #fff;
      background-color: $theme-color;
      border-radius: 15px;
    }
  }
  .news-content {
    margin: 30px 0;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    .news-content-item {
      width: 550px;
      margin: 5px 0;
      display: flex;
      justify-content: space-between;
      cursor: pointer;
      .item-title {
        display: flex;
        align-items: center;
        span {
          width: 440px;
          text-overflow: ellipsis;
          overflow: hidden;
          white-space: nowrap;
        }
      }
      .item-title::before {
        content: "";
        width: 4px;
        height: 4px;
        background-color: #333;
        border-radius: 50%;
        margin-right: 5px;
      }
    }
    .news-content-item:hover {
      color: $theme-color;
    }
  }
}
// 资讯 end

// 教师团队 begin
.teacher-team {
  .teacher-team-item {
    width: 100%;
    background: linear-gradient(90deg, #f5f5f5, #fff);
    box-shadow: 0 4px 15px 1px rgb(212 234 255 / 36%);
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    .teacher-team-item-info {
      width: 350px;
      .name {
        color: #000;
        font-size: 20px;
        font-weight: 700;
        margin-bottom: 10px;
      }
      .info {
        height: 90px;
        line-height: 23px;
        font-size: 14px;
        overflow: hidden;
      }
      .teacher-home-page {
        color: #999;
        font-size: 12px;
        margin-bottom: 15px;
      }
    }
    .teacher-team-item-img {
      width: 160px;
      overflow: hidden;
    }
  }
}
// 教师团队 end

.see-more {
  width: 100%;
  text-align: center;
  border-radius: 3px;
  cursor: pointer;

  .svg-icon {
    color: $theme-color;
    font-size: 36px;
  }
}
.see-more:hover {
  background-color: $theme-color;

  .svg-icon {
    color: #fff;
  }
}

</style>

<style>
.exam-time .el-step__description {
  padding-left: 0 !important;
  padding-right: 0 !important;
}
</style>
