import request from '@/utils/request'
import qs from 'qs'

// 获取专题和二级专题
export function getSpec() {
  return request({
    url: '/product/spec',
    method: 'post'
  })
}


// 根据二级专题查找套餐
export function getTestGood(data) {
  return request({
    url: '/exam/getTestGood',
    method: 'post',
    data: qs.stringify(data)
  })
}
